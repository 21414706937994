import { createStore, createLogger } from 'vuex';
import loaders from './modules/loaders';
import ui from './modules/ui';
import logs from './modules/logs';
import users from './modules/users';
import inbox from './modules/inbox';
import projects from './modules/projects';
import projectCollections from './modules/project-collections';
import programs from './modules/programs';
import instruments from './modules/instruments';
import topics from './modules/topics';
import geographicRegions from './modules/geographic-regions';
import businessSectors from './modules/business-sectors';
import countries from './modules/countries';
import states from './modules/states';
import files from './modules/files';
import interactiveGraphics from './modules/interactive-graphics';
import events from './modules/events';
import eventCollections from './modules/event-collections';
import languages from './modules/languages';
import locations from './modules/locations';
import authorities from './modules/authorities';
import beneficiaries from './modules/beneficiaries';
import projectTypes from './modules/project-types';
import financialSupports from './modules/financial-supports';
import educationTypes from './modules/education-types';
import educations from './modules/educations';
import jobs from './modules/jobs';
import cities from './modules/cities';
import regions from './modules/regions';
import contacts from './modules/contacts';
import contactGroups from './modules/contact-groups';
import employments from './modules/employments';
import contactsData from './modules/contacts-data';
import stints from './modules/stints';
import posts from './modules/posts';
import tags from './modules/tags';
import localWorkgroups from './modules/local-workgroups';
import leStructure from './modules/le-structure';

//const debug = process.env.NODE_ENV !== 'production';
const debug = false;

export default createStore({
    modules: {
        loaders,
        ui,
        logs,
        users,
        inbox,
        projects,
        projectCollections,
        programs,
        instruments,
        topics,
        geographicRegions,
        businessSectors,
        countries,
        states,
        files,
        interactiveGraphics,
        events,
        eventCollections,
        languages,
        locations,
        authorities,
        beneficiaries,
        projectTypes,
        financialSupports,
        educationTypes,
        educations,
        jobs,
        cities,
        regions,
        contacts,
        contactGroups,
        employments,
        contactsData,
        stints,
        posts,
        tags,
        localWorkgroups,
        leStructure,
    },
    strict: debug,
    plugins: debug ? [createLogger()] : []
})