export const tooltips = {
    description: "Fassen Sie bitte kurz zusammen, worum es bei dem Projekt geht. Aus der Zusammenfassung sollte hervorgehen, was die Ausgangslage und die Zielsetzungen waren, welche Aktivitäten/Maßnahmen Sie gesetzt haben, welche Ergebnisse das Projekt erzielt hat und was Sie aus der Umsetzung gelernt haben.",
    tags: "Nennen Sie hier beliebig viele Stichworte, die zum Projekt passen und es charakterisieren. Diese Schlagworte helfen dabei, das Projekt über die Suchfunktion in der Projektdatenbank leichter zu finden.",
    exemplary: "Nennen Sie bis zu drei Gründe in Aufzählungspunkten, was Ihnen im Projekt besonders gut gelungen ist, worauf Sie stolz sind oder warum sich ein Nachahmen lohnen würde. ",
    initialContext: "Beschreiben Sie hier die Ausgangslage und Faktoren, die erklären, warum es wichtig war, dieses Projekt umzusetzen. Beispielsweise gesetzliche Änderungen, veränderte Konsumgewohnheiten, Auswirkungen des Klimawandels, …).",
    initialContextGoals: "Als Reaktion auf den oben genannten Kontext: Was sollte durch das Projekt erreicht werden. (zum Beispiel Reduktion von Treibhausgasemissionen, Schaffung neuer Arbeitsplätze und dergleichen)? Bitte beschreiben Sie hier nicht die konkreten Maßnahmen.",
    additionalValue: "Inwiefern kann das Projekt als Beispiel für erfolgreiches Netzwerken verstanden werden. Auf welche Weise hat Netzwerken zu oben genannten Wirkungen beigetragen?", 
    additionalValueResult: "Beschreiben Sie, welche neuen Möglichkeiten für Vernetzung und Kooperation als Ergebnis des Projekts geschaffen werden konnten.",
    innovations: "Welche neuen oder deutlich verbesserten Prozesse, Dienstleistungen, Produkte und/oder Technologien hat das Projekt hervorgebracht? Bitte denken Sie auch an „soziale Innovationen“, also Handlungsweisen, die durch neue Formen der Zusammenarbeit öffentlicher, wirtschaftlicher und zivilgesellschaftlicher Akteurinnen und Akteure die Beziehungen und die Lebenssituation der Menschen verbessern. Was war im Besonderen dafür ausschlaggebend, damit die Innovation erzielt werden konnte?",
    integrationYoungCitizen: "Bitte beschreiben Sie, auf welche Weise junge Menschen (unter 40 Jahren) beziehungsweise die Perspektive junger Menschen in das Projekt einbezogen waren. Inwiefern haben die beteiligten jungen Menschen beziehungsweise junge Menschen generell durch das Projekt profitiert?",
    integrationFemaleCitizen: "Bitte beschreiben Sie, auf welche Weise Frauen beziehungsweise die Perspektive von Frauen in das Projekt einbezogen waren. Inwiefern haben die beteiligten Frauen durch das Projekt profitiert? Hat sich das Projekt positiv auf Geschlechtergleichstellung ausgewirkt?",
    integrationMinorities: "Bitte beschreiben Sie, auf welche Weise Angehörige von Minderheiten beziehungsweise deren Anliegen in das Projekt einbezogen waren. Inwiefern haben diese Menschen bzw. Angehörige von Minderheiten generell durch das Projekt profitiert? Hat dieses Projekt zur Inklusion beigetragen?",
    learningExperience: "Fassen Sie bitte kurz zusammen, was Sie aus der Umsetzung des Projekts gelernt haben. Beschreiben Sie wesentliche Erfolgsfaktoren, Erfahrungen, Erkenntnisse. Denken Sie auch an ungeplante Ereignisse, die einen Einfluss auf die Projektzielerreichung hatten und worauf Menschen achten sollten, die ebenfalls ein derartiges Projekt umsetzen möchten.",
    transferable: "Inwiefern lässt sich dieses Projekt auf andere Regionen oder Organisationen übertragen?",
    transferDetails: "Wurde dieses Projekt bereits kopiert? In Ihrer Region, in Österreich oder international?",
    fundingMethod: "Bei komplexeren Projekten, die aus mehreren Förderungen unterstützt wurden, unterscheiden Sie bitte, welche davon durch die Gemeinsame Agrarpolitik/ Ländliche Entwicklung gefördert und welche aus anderen Quellen finanziert wurden. Bitte benennen und beschreiben Sie die Maßnahmen im Projekt in chronologischer Reihenfolge:",
    fundingMethodStakeholders: "Wer bzw. welche Stakeholder waren beteiligt und entscheidend für den Projekterfolg?",
    resultsQuantity: "Geben Sie hier all jene Ergebnisse und Wirkungen an, die in Zahlen messbar sind (zum Beispiel Anzahl geschaffener Arbeitsplätze, prozentuelle Steigerung der Einnahmen). Bei LEADER-Projekten: Angabe der relevanten Wirkungsindikatoren",
    resultsQuality: "Beschreiben Sie hier jene Teile der Projektergebnisse, die sich nicht in Zahlen fassen lassen.",
    projectRegion: "Sie können entweder einzelne Bundesländer oder 'Österreichweit' auswählen. Um eine Auswahl zu entfernen, klicken Sie erneut auf das entsprechende Element in der Drop-Down-Liste."
}